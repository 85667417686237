'use client';
import { createTheme } from '@mui/material/styles';

const theme = createTheme({
  breakpoints: {
    values: {
      xs: 320,
      sm: 425,
      md: 720,
      lg: 1080,
      xl: 1440,
    },
  },
  palette: {
    common: {
      black: '#000',
      white: '#fff',
    },
    primary: {
      main: '#2995D3',
      contrastText: '#fff',
    },
    secondary: {
      main: '#fff',
      contrastText: '#2995D3',
    },
    error: {
      main: '#E15554',
      contrastText: '#fff',
    },
    warning: {
      main: '#ff9800',
      contrastText: 'rgba(0, 0, 0, 0.87)',
    },
    info: {
      main: '#2196f3',
      contrastText: '#fff',
    },
    success: {
      main: '#6AD759',
      contrastText: 'rgba(0, 0, 0, 0.87)',
    },
    text: {
      primary: '#000',
      secondary: '#555',
      disabled: 'rgba(0, 0, 0, 0.38)',
    },
    background: {
      paper: '#fff',
      default: '#fafafa',
    },
    action: {
      active: 'rgba(0, 0, 0, 0.54)',
      hover: '#d9d9d9',
      hoverOpacity: 0.04,
      selected: 'rgba(0, 0, 0, 0.08)',
      selectedOpacity: 0.08,
      disabled: 'rgba(0, 0, 0, 0.26)',
      disabledBackground: '#d9d9d9',
      disabledOpacity: 0.38,
      focus: 'rgba(0, 0, 0, 0.12)',
      focusOpacity: 0.12,
      activatedOpacity: 0.12,
    },
  },
  typography: {
    fontFamily: ['Noto Sans', 'sans-serif', 'Roboto', 'Arial'].join(','),
  },
});

export default theme;
