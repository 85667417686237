'use client';
import React from 'react';
import { Button as MuiButton, ButtonProps as MuiButtonProps } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import DoneIcon from '@mui/icons-material/Done';
import { styled } from '@mui/material/styles';
import theme from '../styles/muiTheme';

type ButtonProps = MuiButtonProps & {
  primary?: boolean;
  secondary?: boolean;
  back?: boolean;
  tick?: boolean;
  backgroundColor?: string;
  size?: 'small' | 'medium' | 'large';
  label?: string;
  textColor?: string;
  onClick?: () => void;
};

export const Button = ({
  primary,
  secondary,
  back,
  tick,
  size = 'medium',
  label,
  textColor,
  ...props
}: ButtonProps) => {
  if (primary) {
    secondary = false;
  } else if (secondary) {
    primary = false;
  }

  const StyledButton = styled(MuiButton)({
    borderRadius: back ? '2rem' : size == 'small' ? '.8rem' : '1rem',
    lineHeight: '20px',
    color: textColor,
    textTransform:"none",

    '&:hover, &:active ': {
      background: secondary ? theme.palette.secondary.main : `auto`,
    },
  });

  return (
    <StyledButton type="button" size={size} variant="contained" color={!secondary ? 'primary' : 'secondary'} {...props}>
      {back && <ArrowBackIcon sx={{ transform: 'translate(-5px,0px)' }} />}
      {tick && <DoneIcon sx={{ transform: 'translate(-5px,0px)' }} />}
      {label}
    </StyledButton>
  );
};
