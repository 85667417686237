'use client';

import { Button } from '@ui/button';
import styles from './styles.module.scss';
import { Trans, msg } from "@lingui/macro";
import { useLingui } from "@lingui/react";

export default function Error({ error, reset }: { error: Error; reset: () => void }) {
  const { _ } = useLingui();

  return (
    <div className={styles.error}>
      <h2><Trans>somethingWentWrong</Trans></h2>
      <p>{JSON.stringify(error.message)}</p>
      <Button label={_(msg`tryAgain`)} onClick={() => reset()} />
    </div>
  );
}
